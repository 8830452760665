<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormCustomers
        v-model="customer_id"
        :clearable="true"
        :dense="true"
        :name="`Cliente`"
      />
      <FormItem
        v-model="status"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per stato`"
        :name="`Stato`"
        :type="`select`"
        :values="statuses"
      />
      <FormItem
        v-model="skill_type"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per skill`"
        :name="`Skill`"
        :type="`select`"
        :values="skillTypes"
        @input="onSkillUpdate"
      />
      <FormItem
        v-model="reason"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per motivo`"
        :name="`Motivo`"
        :type="`select`"
        :values="reasons"
      />
      <FormItem
        v-model="service"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per servizio`"
        :name="`Servizio`"
        :type="`select`"
        :values="services"
      />
      <FormItem
        v-model="callback_resolution"
        :clearable="true"
        :cols="2"
        :dense="true"
        :hint="`Esito Ricontatto`"
        :name="`Esito Ricontatto`"
        :type="`select`"
        :values="resolutions"
      />
      <FormItem
        v-model="created_at"
        :lg="2"
        :xl="2"
        :md="2"
        dense
        hint="Ricerca per data di creazione"
        name="Data creazione"
        type="datepicker"
      />
      <FormItem
        v-model="callback_at"
        :lg="2"
        :xl="2"
        :md="2"
        dense
        hint="Ricerca per data di ricontatto"
        name="Data Ricontatto"
        type="datepicker"
      />

      <FormUsers
        v-model="created_by"
        label="Operatore"
        :use-username="true"
        dense
        clearable
      />
      <!--      :additional-filters="{ role_id: [6, 2] }"-->
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormUsers from '@components/forms/FormUsers.vue'
import { createHelpers } from 'vuex-map-fields'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import FormCustomers from '@components/forms/FormCustomers.vue'

const { mapFields: mapFilters } = createHelpers({
  getterType: 'virtualAgentsSessions/getFiltersFields',
  mutationType: 'virtualAgentsSessions/SET_FILTER_FIELDS',
})

const { mapFields: mapReasonsFilters } = createHelpers({
  getterType: 'virtualAgentsReasons/getFiltersFields',
  mutationType: 'virtualAgentsReasons/SET_FILTER_FIELDS',
})

const { mapFields: mapServicesFilters } = createHelpers({
  getterType: 'virtualAgentsServices/getFiltersFields',
  mutationType: 'virtualAgentsServices/SET_FILTER_FIELDS',
})

export default {
  name: 'VirtualAgentsSessionsSectionSearch',
  components: {
    BaseSectionSearch,
    FormCustomers,
    FormItem,
    FormUsers,
  },
  computed: {
    ...mapFilters([
      'created_by',
      'created_at',
      'callback_at',
      'customer_id',
      'reason',
      'sentiment',
      'service',
      'skill_type',
      'status',
      'callback_resolution',
    ]),
    ...mapReasonsFilters({
      r_skill_type: 'skill_type',
    }),
    ...mapServicesFilters({
      s_skill_type: 'skill_type',
    }),
    ...mapGetters('virtualAgentsSessions', ['statuses', 'resolutions']),
    ...mapGetters('virtualAgentsPresets', ['skillTypes', 'sentiments']),
    ...mapState('virtualAgentsReasons', {
      reasons: 'list',
    }),
    ...mapState('virtualAgentsServices', {
      services: 'list',
    }),
  },
  methods: {
    ...mapActions('virtualAgentsSessions', ['getStatuses']),
    ...mapActions('virtualAgentsPresets', ['getSkillTypes', 'getSentiments']),
    ...mapActions('virtualAgentsReasons', {
      getReasons: 'getDropdownList',
    }),
    ...mapActions('virtualAgentsServices', {
      getServices: 'getDropdownList',
    }),
    ...mapMutations('virtualAgentsSessions', {
      resetSessionFilters: 'RESET_FILTER_FIELDS',
      resetStatuses: 'RESET_STATUSES',
    }),
    ...mapMutations('virtualAgentsPresets', {
      resetSkillTypes: 'RESET_SKILL_TYPES',
      resetSentiments: 'RESET_SENTIMENTS',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetReasons: 'RESET_LIST',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetServices: 'RESET_LIST',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      // Restored linked searches
      if (this.skill_type) this.onSkillUpdate(null)
      this.resetSessionFilters()
      this.$emit('search')
    },
    async onSkillUpdate(value) {
      this.r_skill_type = value
      this.s_skill_type = value
      await this.getReasons()
      await this.getServices()
      // If reason is not coherent anymore reset it
      if (this.reason && this.reasons.some((r) => r.value === this.reason)) {
        this.reason = null
      }
      // If service is not coherent anymore reset it
      if (this.service && this.services.some((r) => r.value === this.service)) {
        this.service = null
      }
    },
  },
  async mounted() {
    await Promise.all([
      this.getSentiments(),
      this.getSkillTypes(),
      this.getStatuses(),
      this.getReasons(),
      this.getServices(),
    ])
  },
  beforeDestroy() {
    this.resetSessionFilters()
    this.resetSentiments()
    this.resetSkillTypes()
    this.resetStatuses()
    this.resetReasons()
    this.resetServices()
  },
}
</script>

<style scoped></style>
