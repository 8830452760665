<template>
  <v-container fluid>
    <h3 class="mb-4">Informazioni Sessione</h3>
    <template v-for="field in fields">
      <v-row :key="field.key" align="center">
        <v-col cols="5">
          <span class="font-weight-medium">{{ field.label }}</span>
        </v-col>
        <v-divider vertical />
        <v-col>
          <span>{{ field.value | placeholder('-') }}</span>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col class="text-right">
        <v-btn color="primary" @click="$emit('submitted')">Ok</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { get } from 'lodash'

export default {
  name: 'VirtualAgentsSessionForm',
  computed: {
    ...mapState('virtualAgentsSessions', ['current']),
    ...mapGetters('virtualAgentsSessions', ['labels']),
    paramFields() {
      if (!this.current || !this.current.preset) return []

      return this.current.preset.params_fields.map((f) => {
        let value
        const currValue = this.current.params[f.key]

        if (f.type === 'select') {
          value = f.values.find((v) => v.value === currValue)?.text
        } else if (f.type === 'datepicker') {
          value = this.$options.filters.momentOr(currValue, 'DD/MM/YYYY', '-')
        } else {
          value = currValue
        }

        return {
          key: f.key,
          label: f.name,
          value,
        }
      })
    },
    fields() {
      return [
        {
          key: 'created_by',
          label: 'Creata da',
          value: this.current.created_by,
        },
        {
          key: 'created_at',
          label: 'Creata il',
          value: this.$options.filters.momentOr(
            this.current.created_at,
            'DD/MM/YYYY HH:mm',
            'NA'
          ),
        },
        {
          key: 'reason_name',
          label: 'Motivo di contatto',
          value: this.current.reason_name,
        },
        {
          key: 'service_name',
          label: 'Servizio',
          value: this.current.service_name,
        },
        ...this.paramFields,
        {
          key: 'customer_connected_at',
          label: 'Cliente connesso alle',
          value: this.$options.filters.momentOr(
            this.current.customer_connected_at,
            'DD/MM/YYYY HH:mm',
            'NA'
          ),
        },
        {
          key: 'customer_disconnected_at',
          label: 'Cliente disconnesso alle',
          value: this.$options.filters.momentOr(
            this.current.customer_disconnected_at,
            'DD/MM/YYYY HH:mm',
            'NA'
          ),
        },
        {
          key: 'callback_at',
          label: 'Richiesto Ricontatto',
          value: this.$options.filters.momentOr(
            this.current.callback_at,
            'DD/MM/YYYY HH:mm',
            'NA'
          ),
        },
        {
          key: 'escalation',
          label: 'Escalation',
          value: this.current.escalation ? 'SI' : 'No',
        },

        {
          key: 'callback_done_at',
          label: 'Ricontatto Effettuato',
          value: this.$options.filters.momentOr(
            this.current.callback_done_at,
            'DD/MM/YYYY HH:mm',
            'No'
          ),
        },
        {
          key: 'callback_by',
          label: 'Ricontattato da',
          value: this.current.callback_by,
        },
        {
          key: 'callback_resolution',
          label: 'Esito Ricontatto',
          value: this.current.callback_resolution,
        },
        {
          key: 'callback_count',
          label: 'Numero Ricontatti',
          value: this.current.callback_count,
        },

        {
          key: 'notes',
          label: 'Note',
          value: this.current.notes,
        },
      ]
    },
  },
  methods: {
    get,
  },
}
</script>
