<template>
  <v-container fluid>
    <Form hide-reset send-text="Esita Ricontatto" @submit="onSubmit">
      <h2 class="text-center">
        <small class="text--secondary font-weight-light">Cliente</small><br />
        {{ current.context.common_name }}
      </h2>
      <h3 class="text-center font-weight-medium mt-2">
        {{ current.context.mobile_phone_number }}
      </h3>
      <v-divider />
      <p class="text-center mt-6">
        Ricontattare il cliente nella fascia oraria:
      </p>
      <h2 class="text-center mb-0">
        {{ callback_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </h2>
      <p class="text-center text-caption  mb-6">
        {{ callback_count }} ricontatti effettuati
      </p>

      <v-row dense>
        <FormItem
          v-model="callback_resolution"
          type="select"
          name="Esito Contatto"
          :values="resolutions"
          :cols="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
        />

        <FormItem
          v-model="notes"
          type="textarea"
          name="Inserisci Note"
          hint="Aggiunti eventuali note a corredo"
          :rows="3"
          :cols="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
        />

        <v-slide-y-transition>
          <FormItem
            v-if="isReschedule"
            v-model="new_callback_at"
            type="datetime-local"
            name="Nuova Data ricontatto"
            :cols="12"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
        /></v-slide-y-transition>
      </v-row>
    </Form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { identity, get } from 'lodash'
import { createHelpers } from 'vuex-map-fields'
import FormItem from '@components/forms/FormItem.vue'
import Form from '@components/forms/Form.vue'
import { authComputed } from '@state/helpers.js'
const { mapFields: mapSession } = createHelpers({
  getterType: 'virtualAgentsSessions/getCurrent',
  mutationType: 'virtualAgentsSessions/SET_CURRENT_FIELDS',
})

export default {
  name: 'CallbackForm',
  components: { Form, FormItem },
  computed: {
    ...authComputed,
    ...mapState('virtualAgentsSessions', ['current']),
    ...mapGetters('virtualAgentsSessions', ['labels', 'resolutions']),
    ...mapSession([
      'callback_at',
      'callback_count',
      'callback_done_at',
      'callback_resolution',
      'callback_by',
      'notes',
      'status',
    ]),
    fields() {
      const ensureDefaults = (item) => ({
        formatter: identity,
        ...item,
      })
      return [
        {
          key: 'context.common_name',
          label: 'Cliente',
        },
        {
          key: 'context.mobile_phone_number',
          label: 'Cellulare',
        },
        {
          key: 'output',
          label: 'Output',
        },
        // {
        //   key: 'customer_user',
        //   label: 'Utente',
        //   formatter: (user) =>
        //     user ? `${user.first_name} ${user.last_name}` : 'NA',
        // },
      ].map(ensureDefaults)
    },
    isReschedule() {
      return this.callback_resolution === 'Richiesta Rimodulazione'
    },
  },
  data: () => ({
    new_callback_at: null,
  }),
  methods: {
    ...mapActions('virtualAgentsSessions', ['update']),
    get,
    async onSubmit() {
      if (this.isReschedule) {
        this.callback_count += 1
        this.callback_at = this.$moment(this.new_callback_at).format()
      } else {
        this.callback_done_at = new Date()
        this.status = 'closed'
      }

      this.callback_by = this.beUsername
      await this.update()
      this.$emit('submitted')
    },
  },
}
</script>
