var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('VirtualAgentsSessionsSectionSearch',{on:{"search":function () { return _vm.$refs.table.refresh(); }}}),_c('BaseTable',{ref:"table",attrs:{"title":"Elenco sessioni","module":"virtualAgentsSessions","headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("get")(_vm.labels('statuses'),item.status))+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(_vm._f("get")(item,'created_by'),'-'))+" ")]}},{key:"item.callback_resolution",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(_vm._f("get")(item,'callback_resolution'),'-'))+" ")]}},{key:"item.output.user-slot-choice-date-time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.output['user-slot-choice-date-time'],'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.callback_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.output['callback_date'],'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.callback_at",fn:function(ref){
var item = ref.item;
return [(item.escalation && !item.callback_done_at)?_c('v-icon',{attrs:{"left":"","color":"orange"}},[_vm._v(" mdi-alert-decagram ")]):_vm._e(),_c('span',{class:{ 'text-decoration-line-through': item.callback_done_at }},[_vm._v(" "+_vm._s(_vm._f("momentOr")(item.callback_at,'DD/MM/YYYY HH:mm', '-'))+" ")]),(item.callback_done_at)?[_c('small',{staticClass:"d-block"},[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v(" mdi-check-decagram ")]),_vm._v(" "+_vm._s(_vm._f("momentOr")(item.callback_done_at,'DD/MM/YYYY HH:mm', '-'))+" ")],1)]:_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"600px","retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('SessionForm',{attrs:{"title":null,"edit":true},on:{"submitted":_vm.onSubmitted}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialogCallback),callback:function ($$v) {_vm.dialogCallback=$$v},expression:"dialogCallback"}},[_c('v-card',[_c('v-card-text',[_c('CallbackForm',{attrs:{"title":null,"edit":true},on:{"submitted":_vm.onSubmitted}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }