<template>
  <v-container fluid>
    <VirtualAgentsSessionsSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Elenco sessioni"
      module="virtualAgentsSessions"
      :headers="headers"
      :actions="actions"
    >
      <template v-slot:item.status="{ item }">
        {{ labels('statuses') | get(item.status) }}
      </template>
      <template v-slot:item.created_by="{ item }">
        {{ item | get('created_by') | placeholder('-') }}
      </template>
      <template v-slot:item.callback_resolution="{ item }">
        {{ item | get('callback_resolution') | placeholder('-') }}
      </template>
      <template v-slot:item.output.user-slot-choice-date-time="{ item }">
        {{
          item.output['user-slot-choice-date-time']
            | momentOr('DD/MM/YYYY HH:mm', '-')
        }}
      </template>
      <template v-slot:item.callback_date="{ item }">
        {{ item.output['callback_date'] | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
      <template v-slot:item.callback_at="{ item }">
        <v-icon
          v-if="item.escalation && !item.callback_done_at"
          left
          color="orange"
        >
          mdi-alert-decagram
        </v-icon>

        <span
          :class="{ 'text-decoration-line-through': item.callback_done_at }"
        >
          {{ item.callback_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
        </span>
        <template v-if="item.callback_done_at">
          <small class="d-block">
            <v-icon color="success" small>
              mdi-check-decagram
            </v-icon>
            {{ item.callback_done_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </small>
        </template>
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-text>
          <SessionForm :title="null" :edit="true" @submitted="onSubmitted" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCallback"
      max-width="600px"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-text>
          <CallbackForm :title="null" :edit="true" @submitted="onSubmitted" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import VirtualAgentsSessionsSectionSearch from '@components/virtualagents/VirtualAgentsSessionsSectionSearch.vue'
import SessionForm from '@components/virtualagents/SessionForm.vue'
import CallbackForm from '@components/virtualagents/CallbackForm.vue'
import { mapMutations, mapGetters } from 'vuex'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'VirtualAgentsSessionsSearch',
  page: {
    title: 'Sessioni assistente virtuale',
  },
  components: {
    BaseTable,
    CallbackForm,
    VirtualAgentsSessionsSectionSearch,
    SessionForm,
  },
  data() {
    return {
      dialog: false,
      dialogCallback: false,
      editMode: false,
      headers: [
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Cliente',
          value: 'customer.common_name',
        },
        {
          text: 'Motivo',
          value: 'reason_name',
        },
        {
          text: 'Servizio',
          value: 'service_name',
        },
        {
          text: 'Data Ricontatto',
          value: 'callback_at',
        },
        {
          text: 'Esito Ricontatto',
          value: 'callback_resolution',
        },
        {
          text: 'Creata da',
          value: 'created_by',
        },
        {
          text: 'Creata il',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Dettagli',
          icon: 'mdi-magnify',
          color: 'primary',
          handler: this.openModal,
          onItemCondition: () =>
            this.canUser('virtual_agents_sessions', 'read'),
        },
        {
          label: 'Apri Chat',
          icon: 'mdi-chat',
          color: 'secondary',
          handler: this.openChat,
          onItemCondition: () =>
            this.canUser('virtual_agents_sessions', 'read'),
        },
        {
          label: 'Esita Ricontatto',
          icon: 'mdi-phone-check',
          color: 'danger',
          handler: this.openModalCallback,
          disabled: (item) =>
            !item.callback_at || item.callback_done_at !== null,
          onItemCondition: () =>
            this.canUser('virtual_agents_sessions', 'create'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters('virtualAgentsSessions', ['labels']),
  },
  mounted() {
    this.setScopes(['customer', 'user', 'info', 'reason', 'service', 'preset'])
  },
  beforeDestroy() {
    this.resetFilters()
    this.resetPaginated()
    this.resetList()
    this.setScopes([])
  },
  methods: {
    ...mapMutations('virtualAgentsSessions', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      resetPaginated: 'RESET_PAGINATED_ITEMS',
      resetList: 'RESET_LIST',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    openModalCallback(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialogCallback = true
    },
    openChat(item) {
      window.open(
        '/virtualagent/' + item.id,
        'DescriptiveWindowName',
        'resizable,scrollbars,status'
      )
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.dialogCallback = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
